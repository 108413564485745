<template>
  <div>
    <el-select
      filterable
      v-model="venue_id"
      :placeholder="placeholder+''"
      clearable
      remote
      :remote-method="searchOrgName"
      @change="changeItem"
      ref="agentSelect"
      @hook:mounted="cancalReadOnly"
      @visible-change="cancalReadOnly"
    >
      <el-option
        v-for="(option, index) in venue_list"
        :key="index"
        :value="option.id"
        :label="option.name"
        @click.native="serChnage(option)"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Object,
      default: ()=>{return {}},
    },
    placeholder:{
      type:[String,Number],
      default:"请输入名称"
    }
  },
  data() {
    return {
      venue_list: [],
      selected: "",
      venue_id: "",
    };
  },
  methods: {
    clear(){
        this.venue_list= []
        this.venue_id= ""
      },
       setValue(item){
 this.venue_list= [item]
        this.venue_id= item.id
      },
    serChnage(item) {
      this.$emit("change", item);
    },
    changeItem() {
      this.$emit("update:venueid", this.venue_id);
    },
    searchOrgName(query) {
      let url = "/shared/shareVenue/queryListPage";
      let data = {
        name:query,
        pageSize: 10,
        currentPage: 1,
        type:'venue',
        ...this.condition,
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.venue_list = res.data.data;
        })
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs
          if (Selects.agentSelect) {
            const input = Selects.agentSelect.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>